.projet-container{
    width: 100%;
    height: 300vh;
    /* background: radial-gradient(circle,  #012132 30%, #00141e 100%); */
    background-color: #011018;
    position: relative;
    overflow: hidden;
}
.projet-padding{
    /* margin-top: 5%; */
}
.projet-progress-container{
    position: sticky;
    top:5%;
    left: 0;
    padding-top: 50px;
}

.project-progress-bar{
    height: 10px;
    background-color: #708090;
    width: 80%;
    margin-left: 10%;
 }
.projet-title{
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    color: #E2725B;
    padding-top: 2%;
}
.projet-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.projet-expand-button{
    width: 30px; /* Adjust the width as needed */
  height: 30px; /* Set it equal to the width for a perfect circle */
  border-radius: 50%;
  background-color: #fff; /* Set the background color as needed */
  border: 1px solid #ccc; /* Optional: Add border styling */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
    z-index: 10;
}
.projet-bg-title{
    display: flex;
    justify-content: center;
    color: #ffffff8f;
    font-size: 550px;
    z-index: 1;
    font-weight: bold;
}

.projet-card-1{
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 600px;
    z-index: 10;
    background: linear-gradient(to top left, #00141e 40%, #012f47 );
    border-radius: 5px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    border: 0.1px solid rgba(78, 78, 78, 0.5);
    position: relative;
}
.projet-card-1 .projet-card-image{
    position: absolute;
    width: 400px;
    height: 400px;
    object-fit: fill;
    border-radius: 10px;
    z-index: 10;
    right: 10px;
}
.projet-card-1 .projet-card-title{
    margin-top: 70%;
    width: 400px;
    font-size: 18px;
    margin-left: 3%;
    color: white;
}
.projet-card-1 .projet-card-text{
    width: 450px;
    font-size: 48px;
    margin-left: 5%;
    color: white;
    font-weight: bold;
    z-index: 11;
}
.projet-card-2{
    display: flex;
    flex-direction: column;
    width: 1050px;
    height: 600px;
    z-index: 10;
    background: linear-gradient(to top left, #00141e 40%, #012f47 );
    border-radius: 5px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    margin-left: 1%;
    position: relative;
    overflow: hidden;
    border: 1px solid;
    border: 0.1px solid rgba(78, 78, 78, 0.5);
}
.projet-card-2 .projet-card-image{
    position: absolute;
    top: 100px;
    right: 50px;
    width: 550px;
    height: 450px;
    object-fit:cover;
    z-index: 10;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
    border-radius: 10px;
}
.projet-card-2 .projet-card-title{
    margin-top: 20%;
    width: 400px;
    font-size: 18px;
    margin-left: 3%;
    color: white;
}
.projet-card-2 .projet-card-text{
    width: 450px;
    margin-left: 5%;
    font-size: 48px;
    color: white;
    font-weight: bold;
    z-index: 11;

}
.projet-card-3{
    display: flex;
    flex-direction: column;
    width: 1050px;
    height: 600px;
    z-index: 10;
    background: linear-gradient(to top left, #00141e 40%, #012f47 );
    border-radius: 5px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    border: 0.1px solid rgba(78, 78, 78, 0.5);
}
.projet-card-3 .projet-card-image{
    position: absolute;
    width: 600px;
    height: 500px;
    object-fit:cover;
    z-index: 10;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
    top: 50px;
    right: 50px;
    border-radius: 10px;
}

.projet-card-3 .projet-card-title{
    width: 400px;
    font-size: 18px;
    color: white;
    margin-top: 20%;
    margin-left: 2%;
}
.projet-card-3 .projet-card-text{
    width: 400px;
    font-size: 48px;
    margin-left: 3%;
    color: white;
    font-weight: bold;
    z-index: 11;
}
.projet-card-3 .projet-card-link{
    width: 400px;
    font-size: 18px;
    margin-left: 3%;
    color: beige;
    z-index: 11;
    margin-top: 3%;
    font-weight: bold;
}
.projet-card-4{
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 600px;
    z-index: 10;
    background: linear-gradient(to top left, #00141e 40%, #012f47 );
    border-radius: 5px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    margin-left: 1%;
    position: relative;
    overflow: hidden;
    border: 0.1px solid rgba(78, 78, 78, 0.5);
}
.projet-card-4 .projet-card-image{
    position: absolute;
    top: -100px;
    left: 60px;
    width: 600px;
    height: 500px;
    object-fit:fill;
    z-index: 10;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
}
.projet-card-4 .projet-card-title{
    width: 400px;
    font-size: 48px;
    margin-left: 3%;
    color: white;
    font-weight: bold;
}
.projet-card-4 .projet-card-text{
    width: 300px;
    font-size: 18px;
    color: white;
    z-index: 11;
}
.projet-card-4 .projet-card-textalign{
    display: flex;
    flex-direction: column;
    margin-top: 72%;
    margin-left: 5%;
}
.projet-card-5{
    display: flex;
    flex-direction: column;
    width: 1650px;
    height: 600px;
    z-index: 10;
    background: linear-gradient(to top left, #00141e 40%, #012f47 );
    border-radius: 5px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 1%;
    position: relative;
    overflow: hidden;
    border: 0.1px solid rgba(78, 78, 78, 0.5);
    position: relative;
}
.projet-card-5 .projet-card-image{
    position: absolute;
    left: 5%;
    top: 10%;
    width: 400px;
    height: 400px;
    object-fit:cover;
    z-index: 10;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
    border-radius: 10px;
}
.projet-card-5 .projet-card-image-1{
    position: absolute;
    right: 5%;
    top: 10%;
    width: 400px;
    height: 400px;
    object-fit:fill;
    z-index: 10;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
    border-radius: 10px;
}

.projet-card-5 .projet-card-title{
    width: 500px;
    font-size: 48px;
    color: white;
    margin-left: 35%;
    font-weight: bold;
    z-index: 11;
    text-align: center;
}
.projet-card-5 .projet-card-text{
    margin-top: 10%;
    width: 550px;
    font-size: 18px;
    margin-left: 34%;
    color: white;
    z-index: 11;
}
.projet-card-5 .projet-card-text-1{
    position: absolute;
    bottom: 15%;
    left: 5%;
    width: 400px;
    font-size: 32px;
    font-weight: bold;
    color: white;
    z-index: 11;
}
.projet-card-5 .projet-card-text-2{
    position: absolute;
    right: 5%;
    bottom: 15%;
    width: 400px;
    font-size: 32px;
    font-weight: bold;
    color: white;
    z-index: 11;
}
.projet-card-link-1{
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: beige;
    z-index: 11;
    font-weight: bold;
}
.projet-card-link-2{
    position: absolute;
    bottom: 10%;
    right: 23%;
    color: beige;
    z-index: 11;
    font-weight: bold;
}

.projet-card-line1{
    display: flex;
    flex-direction: row;
}
.projet-card-line2{
    margin-top: 1%;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1660px) {
    .projet-container{
        height: 170vh;
    }
    .projet-bg-title{
        font-size: 500px;
    }
    .projet-card-1{
        width: 500px;
    }
    .projet-card-1 .projet-card-title{
        margin-top: 85%;
    }
    .projet-card-1 .projet-card-text{
        width: 450px;
        font-size: 48px;
        margin-left: 5%;
        color: white;
        font-weight: bold;
        z-index: 11;
    }
    .projet-card-2{
        width: 950px;
    }
    .projet-card-3{

        width: 950px;
    }
    .projet-card-3 .projet-card-image{
        position: absolute;
        width: 500px;
        height: 500px;
        object-fit:cover;
        z-index: 10;
        box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
        top: 50px;
        right: 50px;
        border-radius: 10px;
    }
    .projet-card-4{
        width: 500px;
    }
    .projet-card-4 .projet-card-textalign{
        margin-top: 85%;
    }
    .projet-card-5{
        width: 1450px;
    }
    
    .projet-card-5 .projet-card-title{
        width: 400px;
        font-size: 38px;
    }
    .projet-card-5 .projet-card-text{
        font-size: 16px;
    }
    .projet-card-link-2{
        right: 25%;
    }
    
}

@media only screen and (max-width: 1460px) {
    .projet-bg-title{
        font-size: 500px;
    }
    .projet-card-1{
        width: 500px;
    }
    .projet-card-1 .projet-card-title{
        margin-top: 85%;
    }
    .projet-card-1 .projet-card-text{
        width: 450px;
        font-size: 48px;
        margin-left: 5%;
        color: white;
        font-weight: bold;
        z-index: 11;
    }
    .projet-card-2{
        width: 950px;
    }
    .projet-card-3{

        width: 950px;
    }
    .projet-card-3 .projet-card-image{
        position: absolute;
        width: 500px;
        height: 500px;
        object-fit:cover;
        z-index: 10;
        box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.555);
        top: 50px;
        right: 50px;
        border-radius: 10px;
    }
    .projet-card-4{
        width: 500px;
    }
    .projet-card-4 .projet-card-textalign{
        margin-top: 85%;
    }
    .projet-card-5{
        width: 1450px;
    }
    
    .projet-card-5 .projet-card-title{
        width: 400px;
        font-size: 38px;
    }
    .projet-card-5 .projet-card-text{
        font-size: 16px;
    }
    .projet-card-link-2{
        right: 25%;
    }
    
}


@media only screen and (max-width: 1200px) {
    .projet-bg-title{
        display: flex;
        justify-content: center;
        color: #ffffff8f;
        font-size: 300px;
        z-index: 1;
        font-weight: bold;
    }
    
    .projet-card-1{
        width: 400px;
        height: 500px;
    }
    .projet-card-1 .projet-card-image{
        width: 300px;
        height: 300px;
    }
    .projet-card-1 .projet-card-title{
        margin-top: 85%;
        width: 380px;
        font-size: 16px;
    }
    .projet-card-1 .projet-card-text{
        width: 380px;
        font-size: 38px;
    }
    .projet-card-2{
        width: 550px;
        height: 500px;
    }
    .projet-card-2 .projet-card-image{
        top: -130px;
        right: 0px;
        width: 450px;
        height: 450px;
    }
    .projet-card-2 .projet-card-title{
        margin-top: 62%;
        width: 400px;
        font-size: 16px;
    }
    .projet-card-2 .projet-card-text{
        width: 450px;
        margin-left: 5%;
        font-size: 38px;
    }
    .projet-card-3{
        width: 550px;
        height: 500px;
    }
    .projet-card-3 .projet-card-image{
        top: -150px;
        right: 0px;
        width: 450px;
        height: 450px;
    }
    
    .projet-card-3 .projet-card-title{
        margin-top: 58%;
        width: 400px;
        font-size: 16px;
    }
    .projet-card-3 .projet-card-text{
        width:400px;
        font-size: 38px;
        margin-left: 3%;
    }
    .projet-card-3 .projet-card-link{
        width: 400px;
        font-size: 16px;
        margin-left: 3%;
        margin-top: 1%;
    }
    .projet-card-4{
        width: 400px;
        height: 500px;
    }
    .projet-card-4 .projet-card-image{
        top: -100px;
        left: 60px;
        width: 500px;
        height: 400px;
    }
    .projet-card-4 .projet-card-title{
        width: 400px;
        font-size: 38px;
        margin-left: 3%;
    }
    .projet-card-4 .projet-card-text{
        width: 300px;
        font-size: 16px;
    }
    .projet-card-4 .projet-card-textalign{
        margin-top: 78%;
    }
    .projet-card-5{
        width:950px;
        height: 500px;
    }
    .projet-card-5 .projet-card-image{
        width: 250px;
        height: 250px;
    }
    .projet-card-5 .projet-card-image-1{
        width: 250px;
        height: 250px;
    }
    
    .projet-card-5 .projet-card-title{
        width: 300px;
        font-size: 42px;
        color: white;
        margin-left: 35%;
        font-weight: bold;
        z-index: 11;
        text-align: center;
    }
    .projet-card-5 .projet-card-text{
        margin-top: 8%;
        width: 350px;
        font-size: 16px;
        text-align: center;
        margin-left: 32%;
        color: white;
        z-index: 11;
    }
    .projet-card-5 .projet-card-text-1{
        position: absolute;
        bottom: 25%;
        left: 5%;
        width: 400px;
        font-size: 28px;
        font-weight: bold;
        color: white;
        z-index: 11;
    }
    .projet-card-5 .projet-card-text-2{
        position: absolute;
        right: -10%;
        bottom: 25%;
        width: 400px;
        font-size: 28px;
        font-weight: bold;
        color: white;
        z-index: 11;
    }
    .projet-card-link-1{
        bottom: 20%;
        left: 5%;
    }
    .projet-card-link-2{
        position: absolute;
        bottom: 20%;
        right: 21%;
        color: beige;
        z-index: 11;
        font-weight: bold;
    }
    
    .projet-card-line1{
        display: flex;
        flex-direction: row;
    }
    .projet-card-line2{
        margin-top: 1%;
        display: flex;
        flex-direction: row;
    }
}
@media only screen and (max-width: 820px) {
    .projet-container{
        width: 100%;
        height: 4100px;
    }
    .projet-bg-title{
        font-size: 130px;
    }
    .projet-card-1{
        width: 700px;
        height: 600px;
        z-index: 10;
        margin-left: 0%;
    }
    .projet-card-1 .projet-card-image{
        width: 440px;
        height: 400px;
        top: -20px;
        right: 18%;
    }
    .projet-card-1 .projet-card-title{
        margin-top: 60%;
        width: 550px;
        font-size: 20px;
        margin-left: 6%;
    }
    .projet-card-1 .projet-card-text{
        width: 400px;
        font-size: 48px;
        margin-left: 8%;
    }
    .projet-card-2{
        width: 700px;
        height: 600px;
        border-radius: 5px;
        margin-top: 2%;
        margin-left: 0%;
    }
    .projet-card-2 .projet-card-image{
        width: 420px;
        height: 400px;
        top: -20px;
        right: 18%;
    }
    .projet-card-2 .projet-card-title{
        margin-top: 60%;
        width: 600px;
        font-size: 20px;
        margin-left: 6%;
    }
    .projet-card-2 .projet-card-text{
        width: 600px;
        margin-left: 8%;
        font-size: 48px;
    }
    .projet-card-3{
        width: 700px;
        height: 600px;
        margin-left: 0%;
        margin-top: 2%;
    }
    .projet-card-3 .projet-card-image{
        width: 440px;
        height: 400px;
        top: -20px;
        right: 18%;
    }
    
    .projet-card-3 .projet-card-title{
        width: 500px;
        font-size: 20px;
        margin-top: 60%;
        margin-left: 6%;
    }
    .projet-card-3 .projet-card-text{
        width: 700px;
        font-size: 48px;
        margin-left: 8%;
    }
    .projet-card-3 .projet-card-link{
        width: 350px;
        font-size: 22px;
        margin-left: 8%;
    }
    .projet-card-4{
        width: 700px;
        height: 600px;
        z-index: 10;
        margin-top: 2%;
        margin-left: 0%;
    }
    .projet-card-4 .projet-card-image{
        top: -100px;
        left: 60px;
        width: 600px;
        height: 500px;
    }
    .projet-card-4 .projet-card-title{
        width: 700px;
        font-size: 48px;
        margin-left: 3%;
    }
    .projet-card-4 .projet-card-text{
        width: 500px;
        font-size: 20px;
    }
    .projet-card-4 .projet-card-textalign{
        margin-top: 60%;
        margin-left: 5%;
    }
    .projet-card-5{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 700px;
        height: 1400px;
        z-index: 10;
        margin-top: 2%;
    }
    .projet-card-5 .projet-card-image{
        left: 22%;
        top: 18%;
        width: 400px;
        height: 400px;
    }
    .projet-card-5 .projet-card-image-1{
        left: 22%;
        top: 60%;
        width: 400px;
        height: 400px;
    }
    
    .projet-card-5 .projet-card-title{
        width: 600px;
        font-size: 48px;
        margin-left: 0%;
        color: white;
        font-weight: bold;
        z-index: 11;
        text-align: center;
    }
    .projet-card-5 .projet-card-text{
        width: 500px;
        font-size: 20px;
        margin-left: 0%;
        text-align: center;

    }
    .projet-card-5 .projet-card-text-1{
        position: absolute;
        top: 48%;
        left: 22%;
        width: 400px;
        font-size: 36px;
        font-weight: bold;
        color: white;
        z-index: 11;
    }
    .projet-card-5 .projet-card-text-2{
        position: absolute;
        left: 22%;
        bottom: 7%;
        width: 400px;
        font-size: 36px;
        font-weight: bold;
        color: white;
        z-index: 11;
    }
    .projet-card-link-1{
        position: absolute;
        top: 52%;
        left: 22%;
        color: beige;
        z-index: 11;
        font-weight: bold;
        font-size: 20px;
    }
    .projet-card-link-2{
        position: absolute;
        bottom: 4%;
        left: 22%;
        color: beige;
        z-index: 11;
        font-weight: bold;
        font-size: 20px;
    }
    
    .projet-card-line1{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .projet-card-line2{
        margin-top: 1%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
/* Media query for mobile phones */
@media only screen and (max-width: 540px) {
    .projet-container{
        width: 100%;
        height: 4100px;
    }
    .projet-bg-title{
        font-size: 130px;
    }
    .projet-card-1{
        width: 380px;
        height: 600px;
        z-index: 10;
        margin-left: 0%;
    }
    .projet-card-1 .projet-card-image{
        width: 440px;
        height: 400px;
        top: -20px;
        right: -60px;
    }
    .projet-card-1 .projet-card-title{
        margin-top: 105%;
        width: 350px;
        font-size: 18px;
        margin-left: 6%;
    }
    .projet-card-1 .projet-card-text{
        width: 100px;
        font-size: 42px;
        margin-left: 8%;
    }
    .projet-card-2{
        width: 380px;
        height: 600px;
        border-radius: 5px;
        margin-top: 2%;
        margin-left: 0%;
    }
    .projet-card-2 .projet-card-image{
        width: 420px;
        height: 400px;
        top: -20px;
        right: -20px;
    }
    .projet-card-2 .projet-card-title{
        margin-top: 105%;
        width: 400px;
        font-size: 16px;
        margin-left: 6%;
    }
    .projet-card-2 .projet-card-text{
        width: 350px;
        margin-left: 8%;
        font-size: 42px;
    }
    .projet-card-3{
        width: 380px;
        height: 600px;
        margin-left: 0%;
        margin-top: 2%;
    }
    .projet-card-3 .projet-card-image{
        width: 440px;
        height: 400px;
        top: -20px;
        right: -60px;
    }
    
    .projet-card-3 .projet-card-title{
        width: 350px;
        font-size: 16px;
        margin-top: 105%;
        margin-left: 6%;
    }
    .projet-card-3 .projet-card-text{
        width: 350px;
        font-size: 42px;
        margin-left: 8%;
    }
    .projet-card-3 .projet-card-link{
        width: 350px;
        font-size: 16px;
        margin-left: 8%;
    }
    .projet-card-4{
        width: 380px;
        height: 600px;
        z-index: 10;
        margin-top: 2%;
        margin-left: 0%;
    }
    .projet-card-4 .projet-card-image{
        top: -100px;
        left: 60px;
        width: 600px;
        height: 500px;
    }
    .projet-card-4 .projet-card-title{
        width: 350px;
        font-size: 42px;
        margin-left: 3%;
    }
    .projet-card-4 .projet-card-text{
        width: 300px;
        font-size: 16px;
    }
    .projet-card-4 .projet-card-textalign{
        margin-top: 110%;
        margin-left: 5%;
    }
    .projet-card-5{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 380px;
        height: 1400px;
        z-index: 10;
        margin-top: 2%;
    }
    .projet-card-5 .projet-card-image{
        left: 2.5%;
        top: 18%;
    }
    .projet-card-5 .projet-card-image-1{
        left: 2.5%;
        top: 60%;
        width: 400px;
        height: 400px;
    }
    
    .projet-card-5 .projet-card-title{
        width: 440px;
        font-size: 42px;
        margin-left: 0%;
        color: white;
        font-weight: bold;
        z-index: 11;
        text-align: center;
    }
    .projet-card-5 .projet-card-text{
        width: 400px;
        font-size: 16px;
        margin-left: 0%;
        text-align: center;

    }
    .projet-card-5 .projet-card-text-1{
        position: absolute;
        top: 48%;
        left: 5%;
        width: 400px;
        font-size: 32px;
        font-weight: bold;
        color: white;
        z-index: 11;
    }
    .projet-card-5 .projet-card-text-2{
        position: absolute;
        left: 5%;
        bottom: 7%;
        width: 400px;
        font-size: 32px;
        font-weight: bold;
        color: white;
        z-index: 11;
    }
    .projet-card-link-1{
        position: absolute;
        top: 52%;
        left: 5%;
        color: beige;
        z-index: 11;
        font-weight: bold;
    }
    .projet-card-link-2{
        position: absolute;
        bottom: 4%;
        left: 5%;
        color: beige;
        z-index: 11;
        font-weight: bold;
    }
    
    .projet-card-line1{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .projet-card-line2{
        margin-top: 1%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 375px) {
    .projet-container{
        width: 100%;
        height: 4100px;
    }
    .projet-bg-title{
        font-size: 130px;
    }
    .projet-card-1{
        width: 350px;
    }
    .projet-card-1 .projet-card-image{
        width: 400px;
        height: 350px;
    }
    .projet-card-2{
        width: 350px;
    }
    .projet-card-2 .projet-card-image{
        width: 380px;
        height: 350px;
    }
    .projet-card-3{
        width: 350px;
    }
    .projet-card-3 .projet-card-image{
        width: 400px;
        height: 350px;
        top: -20px;
        right: -60px;
    }
    
    .projet-card-4{
        width: 350px;
    }
    .projet-card-4 .projet-card-image{
        height: 450px;
    }
    .projet-card-4 .projet-card-title{
        width: 320px;
        font-size: 38px;
        margin-left: 3%;
    }
    .projet-card-5{
        width: 350px;
        height: 1400px;
    }
    .projet-card-5 .projet-card-image{
        width: 350px;
        height: 350px;
        left: 2.5%;
        top: 18%;
    }
    .projet-card-5 .projet-card-image-1{
        left: 2.5%;
        top: 60%;
        width: 350px;
        height: 350px;
    }
    
    .projet-card-5 .projet-card-title{
        width: 320px;
        font-size: 38px;
        margin-left: 0%;
    }
    .projet-card-5 .projet-card-text{
        width: 320px;
        font-size: 16px;
        margin-left: 0%;
    }
    .projet-card-5 .projet-card-text-1{
        top: 46%;
        left: 5%;
        width: 400px;
        font-size: 32px;
    }
    .projet-card-5 .projet-card-text-2{
        bottom: 9%;
        width: 350px;
        font-size: 32px;
    }
    .projet-card-link-1{
        top: 50%;
    }
    .projet-card-link-2{
        bottom: 6%;
    }
}
