.tv-canvas-container{
    position: absolute;
    padding-left: 550px;
    padding-top: 150px;
    z-index: 5;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 1460px) {
    .tv-canvas-container{
        position: absolute;
        padding-left: 0px;
        padding-top: 500px;
        z-index: 5;
        width: 400px;
        height: 50px;
    }

}
@media only screen and (max-width: 1200px) {
    .tv-canvas-container{
        position: absolute;
        padding-left: 0px;
        padding-top: 350px;
        z-index: 5;
        width: 100%;
        height: 50px;
    }

}
@media only screen and (max-width: 820px) {
    .tv-canvas-container{
        position: absolute;
        padding-left: 0px;
        padding-top: 500px;
        z-index: 5;
        width: 100%;
        height: 100%;
    }
}

/* Media query for mobile phones */
@media only screen and (max-width: 550px) {
    .tv-canvas-container{
        position: absolute;
        padding-right: 200px;
        padding-top: 500px;
        z-index: 5;
        width: 400px;
        height: 50px;
    }

}
@media only screen and (max-width: 375px) {
    .tv-canvas-container{
        position: absolute;
        padding-left: 0px;
        padding-top: 500px;
        z-index: 5;
        width: 400px;
        height: 50px;
    }

}