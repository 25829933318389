.container-navbar{
    width: 100%;
    height: 80px;
    position: fixed;
    background-color: #00141e;
    z-index: 1000;
}

.content{
    width: 400px;
    height: 80px;
    color: white;
    font-family: Brandon bld,sans-serif;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
    padding-right: 30%;
}
.icon{
    height: 12%;
    width: 12%;
    cursor: pointer;
}
.info{
    display: flex;
    align-items: center;
    text-align:center;
    width: auto;
    margin-left: 3%;
    margin-right: 3%;
    position: relative;
}
.info a{
    color: #ffffff;
    text-decoration: none;
}

.info a::after {
    content: '';
    position: absolute;
    bottom: 35%;
    left: 0;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: width 0.3s ease;
  }
  
  .info a:hover::after {
    width: 100%;
    background-color: #fff2eb;
  }
.nav-container{
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;

}
.hamburger-menu-logo{
    width: 40px;
}
.hamburger-menu{
    margin-right: 2%;
}
.navbar-logo-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hamburger-button{
        z-index: 5;
        width: 50px;
        height: 50px;
        position: fixed;
        top:15px;
        left: 230px;
        background-color: transparent;
        border: none;
        cursor: pointer;
}
.hamburger-button:hover{
    transition: 0.3 ease-in;
    scale: 1.2;
}
.navbar-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 500px;
    background: #fff2eb;
    height: 1000px;
    bottom: 0;
    z-index: 2; 
}
.navbar-links{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.navbar-links a{
    text-decoration: none;
    color: black;
    font-size: 24px;
}

.navbar-links a:hover{
    text-decoration: underline;
    color: black;
    font-size: 24px;
}
.navbar-polygames-logo{
    position: fixed;
    width: 80px;
    top:15px;
    right: 10%;
}

@media (max-width: 1200px) {
    .navbar-links{
        margin-top: -20%;
        width: 100%;
    }
    .hamburger-button{
        left: 100px;
    }
    .content{
        padding-right: 35%;
    }
    .navbar-bg{
        height: 1400px;
    }
}
@media (max-width: 820px) {

    .hamburger-button{
        left: 100px;
    }
    .content{
        font-size: 16px;
        padding-right: 25%;
    }
}
@media (max-width: 550px) {
    .content {
        display: none;
    }
    .navbar-links{
        width: 85%;
    }
    .navbar-bg{
        height: 1000px;
    }
    .hamburger-button{
        left: 30px;
    }
    .navbar-polygames-logo{
        right: 10%;
        width: 70px;
    }
}
@media (max-width: 375px) {
    .content {
        display: none;
    }
    .navbar-links{
        width: 375px;
        margin-top: -40%;
    }
    .hamburger-button{
        left: 30px;
}
}