
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
.container-game_jam{
    width: 100%;
    height: 950px;
    background-color: #5a6b72;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.game_jam-title-container{
    display: flex;
    flex-direction: row;

}
.game_jam-title{
    font-family: Source Code Pro, monospace;
    font-size: 40px;
}

.game_jam-title-2{
    font-family: Source Code Pro, monospace;
    font-size: 40px;
    margin-top: 5%;
}

.game_jam-image{
    width: 100%;
    height: 970px;
}
.Game_jam_join_button{
    position: absolute;
    width: 400px;
    height: 140px;
    top: 65%;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.349);
    z-index: 999;
    font-size: 50px;
    transition: background-color, box-shadow 0.5s ease;
    background-color: rgb(175, 186, 238);
    font-family: 'Raleway', sans-serif;
    font-weight:600;
    left: 55%;
}

.Game_jam_join_button:hover{
    background-color: rgb(220, 241, 253);
    box-shadow: 20px -20px 1px rgb(175, 186, 238);
    color: rgb(175, 186, 238);
}

@media only screen and (max-width: 1200px) {
    .container-game_jam{
        width: 100%;
        height: 700px;
        background-color: #5a6b72;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .game_jam-image{
        width: 100%;
        height: 700px;
    }
    .Game_jam_join_button{
        position: absolute;
        width: 300px;
        height: 100px;
        top: 65%;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.349);
        z-index: 999;
        font-size: 40px;
        transition: background-color, box-shadow 0.5s ease;
        background-color: rgb(175, 186, 238);
        font-family: 'Raleway', sans-serif;
        font-weight:600;
        left: 55%;
    }
}
@media only screen and (max-width: 820px) {
    .container-game_jam{
        width: 100%;
        height: 500px;
        background-color: #5a6b72;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .game_jam-image{
        width: 100%;
        height: 500px;
    }
    .Game_jam_join_button{
        position: absolute;
        width: 250px;
        height: 80px;
        top: 65%;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.349);
        z-index: 999;
        font-size: 30px;
        transition: background-color, box-shadow 0.5s ease;
        background-color: rgb(175, 186, 238);
        font-family: 'Raleway', sans-serif;
        font-weight:600;
        left: 55%;
    }
}
/* Media query for mobile phones */
@media only screen and (max-width: 550px) {
    .container-game_jam{
        width: 100%;
        height: 220px;
        background-color: #5a6b72;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .game_jam-image{
        width: 100%;
        height: 270px;
    }
    .Game_jam_join_button{
        position: absolute;
        width: 100px;
        height: 40px;
        top: 65%;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.555);
        z-index: 999;
        font-size: 16px;
        transition: background-color, box-shadow 0.5s ease;
        background-color: rgb(175, 186, 238);
        font-family: 'Raleway', sans-serif;
        font-weight:600;
        left: 55%;
    }
    .Game_jam_join_button:hover{
        background-color: rgb(220, 241, 253);
        box-shadow: 10px -10px 1px rgb(175, 186, 238);
        color: rgb(175, 186, 238);
    }
}