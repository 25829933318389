.container-commentaire{
    height: 1050px;
    width: 100%;
    background: linear-gradient(to top left, #00141e 40%, #012f47 );
    display: flex;
    flex-direction: column;

}
.commentaire-section{
    display: flex;
    flex-direction: row;
}
.commentaire-text{
    color: rgb(255, 255, 255);
    margin-top: 7%;
    font-size: 18px;
    width: 80%;
    /* font-style: ; */
}
.commentaire-title{
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-weight: bold;
}

.commentaire-nom{
    width: 800px;
    height: 40px;
    border-radius: 5px;
}
.commentaire-email{
    width: 800px;
    height: 40px;
    margin-top: 5%;
    border-radius: 5px;
}

.commentaire-form-text{
    height: 400px;
    width: 800px;
    margin-top: 5%;
    border-radius: 5px;
}
.commentaire-form{
    display: flex;
    flex-direction: column;
}
.commentaire-text-container{
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
}
.commentaire-form-container{
    margin-top: 10%;
    margin-right: 5%;
}
.commentaire-button{
    margin-top: 5%;
    width: 150px;
    height: 50px;
    background-color:  rgb(255, 247, 237);
    border-radius: 30px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}
.commentaire-button:hover{
    color: white;
    background-color: #5a6b72 ;
}
.commanditaire-container{
    height: 100px;
    margin-left: 5%;
    margin-top: -5%;
}
.commanditaire-image{
    height: 100px;
    width: 100px;
    border-radius: 5px;
    cursor: pointer;
}
.commanditaire-image:hover{
    scale: 1.2;
    transition: scale ease-in-out 0.5s;
}
.commanditaire-title{
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    
}
.commanditaire-list{
    display: flex;
    flex-direction: row;
    margin-right: 10%;
}
a .commanditaire-image-doublestalion{
    height: 100px;
    width: 100px;
    border-radius: 5px;
    margin-left: 30%;
    cursor: pointer;
}
.commanditaire-image-doublestalion:hover{
    scale: 1.2;
    transition: scale ease-in-out 0.5s;
}
@media only screen and (max-width: 1460px) {
    .logo-contact{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact-copyrights{
        margin-top: 5%;
    }
    .logo-contact.polytechnique {
        margin-left: 5%;

      }
}
@media only screen and (max-width: 1200px) {
    .commentaire-text-container{
        width: 300px;
    }
    .commentaire-nom{
        width: 450px;
        height: 40px;
        border-radius: 5px;
    }
    .commentaire-email{
        width: 450px;
        height: 40px;
        margin-top: 5%;
        border-radius: 5px;
    }
    .commentaire-form-text{
        height: 400px;
        width: 450px;
        margin-top: 5%;
        border-radius: 5px;
    }
    .commanditaire-container{
        margin-top: 5%;
    }
}
@media only screen and (max-width: 820px) {
    .commentaire-text-container{
        width: 500px;
    }
    .commentaire-nom{
        width: 330px;
        height: 40px;
        border-radius: 5px;
    }
    .commentaire-email{
        width: 330px;
        height: 40px;
        margin-top: 5%;
        border-radius: 5px;
    }
    .commentaire-form-text{
        height: 400px;
        width: 330px;
        margin-top: 5%;
        border-radius: 5px;
    }
    .commentaire-text{
        width: 300px;
    }
}

@media (max-width: 550px) {
    .container-commentaire{
        display: flex;
        flex-direction: column;
        height: 1500px;
    }
    .commentaire-section{
        display: flex;
        flex-direction: column;
    }
    .commentaire-text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
        width: 100%;
    }
    .commentaire-title{
        font-size: 48px;
    }
    .commentaire-form-container{
        display: flex;
        justify-content: center;
        margin-right: 0%;
        order: 1;
    }
    .commentaire-nom{
        width: 350px;
        height: 40px;
        border-radius: 5px;
    }
    .commentaire-email{
        width: 350px;
        height: 40px;
        margin-top: 5%;
        border-radius: 5px;
    }
    .commentaire-form-text{
        height: 400px;
        width: 350px;
        margin-top: 5%;
        border-radius: 5px;
    }
    .commanditaire-container{
        order: 2;
    }
    .commanditaire-container{
        margin-top: 20%;
        height: 100px;
        margin-left: 10%;
    }
    .commentaire-text{
        text-align: center;
    }
    .commanditaire-image{
        height: 80px;
        width: 80px;
        border-radius: 5px;
        cursor: pointer;
    }
    a .commanditaire-image-doublestalion{
        height: 80px;
        width: 80px;
        border-radius: 5px;
        margin-left: 30%;
        cursor: pointer;
    }
    .commanditaire-list{
         margin-top: 10%;
    }
}
@media only screen and (max-width: 375px) {
    .logo-contact{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact-copyrights{
        margin-top: 5%;
    }
    .logo-contact.polytechnique {
        margin-left: 5%;
      }
}