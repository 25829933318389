
.container-equipe{
    width: 100%;
    height: 400px;
    background-color: #5a6b72;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
}
.equipe-title-container{
    display: flex;
    flex-direction: row;

}
.equipe-title{
    font-family: Source Code Pro, monospace;
    font-size: 40px;
}

.equipe-title-2{
    font-family: Source Code Pro, monospace;
    font-size: 40px;
    margin-top: 5%;
}

@media only screen and (max-width: 1200px) {
    .container-commandite{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }
    .equipe-title-container{
        display: flex;
        flex-direction: row;
    
    }
    .equipe-title{
        font-family: Source Code Pro, monospace;
        font-size: 36px;
        width: 200px;
    }
    
    .equipe-title-2{
        font-family: Source Code Pro, monospace;
        font-size: 36px;
        margin-top: 10%;
        width: 400px;
    }
}
@media only screen and (max-width: 820px) {
    .container-commandite{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }
    .equipe-title-container{
        display: flex;
        flex-direction: row;
    
    }
    .equipe-title{
        font-family: Source Code Pro, monospace;
        font-size: 32px;
        width: 200px;
    }
    
    .equipe-title-2{
        font-family: Source Code Pro, monospace;
        font-size: 32px;
        margin-top: 10%;
        width: 400px;
    }
}
/* Media query for mobile phones */
@media only screen and (max-width: 550px) {
    .container-equipe{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }
    .equipe-title-container{
        display: flex;
        flex-direction: row;
    
    }
    .equipe-title{
        font-family: Source Code Pro, monospace;
        font-size: 20px;
        width: 130px;
    }
    
    .equipe-title-2{
        font-family: Source Code Pro, monospace;
        font-size: 20px;
        margin-top: 10%;
        width: 180px;
    }
}