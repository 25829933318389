.container-a-propos{
    width: 100%;
    height: 1050px;
    background-color: #011018;
    position: relative;
}
#left-title{
    position: absolute;
    top:25%;
    left: 15%;
    text-align: left;
}
#sub-title{
    position: relative;
    width: 600px;
    margin-top: 2%;
    font-size: 40px;
    font-family: Source Code Pro, monospace;
    letter-spacing: -1.5px;
    z-index: 10;
    color: #ffffff;
}
#title-a-propros{
    position: relative;
    color: #ffffff;
    font-family: Consolas, monospace;
    font-size: 100px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    width: 800px;
    text-align: left;
    letter-spacing: -2%;
    z-index: 10;
}
.apropros-bg-text{
    position: absolute;
    top: 55%;
    font-size: 340px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    opacity: 10%;
    white-space: nowrap;
    color: white;
    font-weight: bold;
    width: 50%;
    z-index: 1;
}

.apropros-room{
    position: absolute;
}
.apropros-HideCanvas{
    bottom: 0;
    position: absolute;
    width: 500px;
    height: 600px;
    z-index: 20;
}
.button-game_jam{
    position: absolute;
    top: 70%;
    left: 15.5%;
    background-color: white;
    width: 200px;
    height: 70px;
    border: none;
    border-radius: 10px;
    z-index: 999;
    transition: background-color 0.5s ease;
    background-color: rgb(219, 204, 174);
    color: black;
    font-size: 20px;
}
.button-game_jam:hover{
background-color: rgb(248, 175, 80);
color: white;
}
@media only screen and (max-width: 1460px) {
    #left-title{
        position: absolute;
        top:25%;
        left: 15%;
        text-align: left;
    }
    #sub-title{
        position: relative;
        width: 600px;
        margin-top: 2%;
        font-size: 40px;
        font-family: Source Code Pro, monospace;
        letter-spacing: -1.5px;
        z-index: 10;
        color: #ffffff;
    }
    #title-a-propros{
        font-size: 50px;
        
    }
    .apropros-bg-text{
        position: absolute;
        top: 55%;
        font-size: 340px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        opacity: 10%;
        white-space: nowrap;
        color: white;
        font-weight: bold;
        width: 50%;
        z-index: 1;
    }

}
@media only screen and (max-width: 1200px) {
    #left-title{
        position: absolute;
        top:25%;
        left: 15%;
        text-align: left;
    }
    #sub-title{
        position: relative;
        width: 600px;
        margin-top: 2%;
        font-size: 40px;
        font-family: Source Code Pro, monospace;
        letter-spacing: -1.5px;
        z-index: 10;
        color: #ffffff;
    }
    #title-a-propros{
        font-size: 50px;
        
    }
    .apropros-bg-text{
        position: absolute;
        top: 55%;
        font-size: 170px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        opacity: 10%;
        white-space: nowrap;
        color: white;
        font-weight: bold;
        width: 50%;
        z-index: 1;
    }
    .button-game_jam{
        position: absolute;
        top: 50%;
        left: 15.5%;
        background-color: white;
        width: 200px;
        height: 70px;
        border: none;
        border-radius: 10px;
        z-index: 999;
        transition: background-color 0.5s ease;
        background-color: rgb(219, 204, 174);
        color: black;
        font-size: 20px;
    }
}
@media only screen and (max-width: 820px) {
    #left-title{
        position: absolute;
        top:25%;
        left: 10%;
        text-align: left;
    }
    #sub-title{
        position: relative;
        width: 600px;
        margin-top: 2%;
        font-size: 40px;
        font-family: Source Code Pro, monospace;
        letter-spacing: -1.5px;
        z-index: 10;
        color: #ffffff;
    }
    #title-a-propros{
        font-size: 50px;
        
    }
    .apropros-bg-text{
        font-size: 140px;
    }
    .button-game_jam{
        position: absolute;
        top: 50%;
        left: 10%;
        background-color: white;
        width: 200px;
        height: 70px;
        border: none;
        border-radius: 10px;
        z-index: 999;
        transition: background-color 0.5s ease;
        background-color: rgb(219, 204, 174);
        color: black;
        font-size: 20px;
    }

}
/* Media query for mobile phones */
@media only screen and (max-width: 550px) {
    #left-title{
        top:20%;
        left: 0%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #sub-title{
        width: 390px;
        margin-top: 10%;
        font-size: 24px;
    }
    #title-a-propros{
        font-size: 50px;
        width: 390px;
        text-align: center;
    }
    .apropros-bg-text{
        position: absolute;
        top: 55%;
        font-size: 80px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        opacity: 10%;
        white-space: nowrap;
        color: white;
        font-weight: bold;
        width: 50%;
        z-index: 1;
    }
    .button-game_jam{
        position: absolute;
        top: 45%;
        left: 29%;
        background-color: white;
        width: 170px;
        height: 50px;
        border: none;
        border-radius: 10px;
        z-index: 999;
        transition: background-color 0.5s ease;
        background-color: rgb(219, 204, 174);
        color: black;
        font-size: 20px;
    }
}
@media only screen and (max-width: 375px) {
    .apropros-bg-text{
        font-size: 70px;
    }
}