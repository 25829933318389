.container-contact{
    background: #435055;
    height: 400px;
}
.logo-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;
    margin-top: 5%;
    margin-right: 5%;
}
.logo{
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
}
.logo-contact{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 20%;
    padding-left: -5%;
}
#logo-poly{
    width: 50%;
}
.contact-title-container{
    position: relative;
  }
#title-contact{
    display: flex;
    justify-content: center;
    padding-top: 2%;
    font-size: 32px;
    color: white;
    position: relative;
}
.line-contact {
    top:55%;
    position: absolute;
    left: 46.5%;
  height: 30px;
  width: 10px;
  background-color: white;
  margin-right: 10px; /* Adjust the spacing between the line and the title */
  vertical-align: middle;
  content: "";
  display: inline-block;
  }
.contact-copyrights{
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.contact-copyrights-logo{
    margin-left: 1%;
    display: flex;
    color: white;
    height: 18px;
}
.contact-copyrights-text{
    font-size: 12px;
    color: white;
    letter-spacing: 2px;
}
.contact-copyrights-polygames{
    display: flex;
    align-items: center;
    margin-left: 10%;
    font-size: 18px;
}
.contact-link{
    margin-left: 5%;
    margin-right: 5%;
}
@media only screen and (max-width: 1460px) {
    .logo-contact{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact-copyrights{
        margin-top: 5%;
    }
    .logo-contact.polytechnique {
        margin-left: 5%;

      }
}

/* Media query for mobile phones */
@media only screen and (max-width: 550px) {
    .contact-copyrights{
        margin-top: 5%;
    }
    .logo-container{
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }
    .logo-contact{
        display: flex;
        width: 50%;
        justify-content: center;
        order: 1;
        margin-top: 3%;
    }

    .logo-contact.polygames {
        order: -1; /* Place PolyGames at the top */
      }
    
      .logo-contact.polytechnique {
        order: 0; /* Place Polytechnique at the bottom */

      }
      .logo-polytechnique{
        width: 80%;
      }
}
@media only screen and (max-width: 375px) {
    .container-commandite{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        height: 250px;
    }
    .commandite-title-1{
        font-size: 18px;
        padding-top: 10%;
        width: 100px;
    }
    .commandite-title-2{
        font-size: 18px;
        padding-top: 20% ;
        width: 100px;
    }
    .commandite-title-3{
        font-size: 18px;
        padding-top: 30%;
        width: 100px;
    }
}